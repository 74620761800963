<template>
  <navigation index="productList" ref="navigation">
    <div :style="{ height: ContentHeight - 100 + 'px' }">
      <div
        class="topnavigation"
        style="gap: 10px; justify-content: space-between"
      >
        <div style="display: flex; gap: 10px">
          <el-button
            type="primary"
            @click="
              OpenCreateGoodsWindow({
                Name: '',
                MnemonicCode: '',
                ProductType: '',
                Price: 0,
                Code: '',
                IncludeTax: false,
                OldPrice: 0,
                Sort: 0,
                Images: '',
                Description: '',
                Cost: 0,
                MnemonicCode: '',
                BrandId: '',
                GoodsCategoryId: '',
                TaxRate: 10,
                PrecioDeVentas: [],
              })
            "
            >新增</el-button
          >

          <el-input
            v-model="GoodsSearch"
            @keydown.enter="GetGoods"
            style="width: 220px"
            :placeholder="tools.GetLanguageValue('web.通过输入名称和Code查询')"
          ></el-input>

          <!-- <el-checkbox-group v-model="SearchProductTypes">
            <el-button v-for="(attr, index) in ProductTypes" :key="index">
              <el-checkbox :label="attr.Key"> {{ attr.Value }}</el-checkbox>
            </el-button>
          </el-checkbox-group> -->

          <el-button type="primary" @click="GetGoods">
            {{ tools.GetLanguageValue("web.查询") }}
          </el-button>
        </div>

        <div style="display: flex; gap: 10px; margin-right: 10px">
          <el-button @click="StoreSettingsWindow = true">
            {{ tools.GetLanguageValue("system.public.临时商品")
            }}{{ tools.GetLanguageValue("web.设置") }}
          </el-button>
        </div>
      </div>

      <div style="width: calc(100% - 1px); height: 100%">
        <div style="width: calc(100% - 15px)">
          <el-table
            border
            :data="GoodsDatas"
            :height="TableHeight + 'px'"
            style="width: calc(100% - 1px)"
          >
            <el-table-column type="index" width="50"> </el-table-column>

            <el-table-column
              :label="tools.GetLanguageValue('web.商品名称')"
              width="320"
              prop="Name"
            >
              <template #default="scope">
                <p
                  v-if="scope.row.ShowAllName"
                  @click="scope.row.ShowAllName = false"
                >
                  {{ scope.row.Name }}
                </p>
                <p
                  v-else
                  class="overflow"
                  @click="scope.row.ShowAllName = true"
                >
                  {{ scope.row.Name }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              :label="tools.GetLanguageValue('web.排序')"
              width="80"
              prop="Sort"
            />
            <el-table-column
              :label="tools.GetLanguageValue('web.商品类型')"
              width="100"
              prop="ProductTypeFormat"
            />

            <!-- <el-table-column :label=" tools.GetLanguageValue('web.图片')" width="100">
              <template #default="scope">
                <div style="display: flex; align-items: center">
                  <el-image
                    width="60"
                    height="60"
                    :src="scope.row.ImagesFormat"
                    class="avatar"
                    @click="
                      () => {
                        this.ImageListDetailWindow = true;
                        this.ImageListDetailUrl = scope.row.ImagesFormat;
                      }
                    "
                  />
                </div>
              </template>
            </el-table-column> -->
            <el-table-column
              :label="tools.GetLanguageValue('web.属性')"
              width="160"
            >
              <template #default="scope">
                <el-tag
                  v-for="(item, index) in scope.row.SkuAttribute"
                  :key="index"
                  style="margin: 3px"
                >
                  {{ item.AttributeName }}
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column
              :label="tools.GetLanguageValue('web.单价')"
              width="80"
              prop="Price"
            />

            <el-table-column
              :label="tools.GetLanguageValue('web.是否含税')"
              width="100"
            >
              <template #default="scope">
                <el-switch v-model="scope.row.IncludeTax" disabled />
              </template>
            </el-table-column>
            <el-table-column
              :label="tools.GetLanguageValue('web.成本价')"
              width="80"
              prop="Cost"
            />
            <el-table-column
              :label="tools.GetLanguageValue('web.编码')"
              width="80"
              prop="Code"
            />

            <el-table-column
              :label="tools.GetLanguageValue('web.税率')"
              width="80"
              prop="TaxRate"
            />
            <el-table-column
              :label="tools.GetLanguageValue('web.助记码')"
              prop="MnemonicCode"
              width="120"
            />
            <el-table-column
              :label="tools.GetLanguageValue('web.备注')"
              prop="Description"
              width="120"
            />

            <el-table-column
              :label="tools.GetLanguageValue('web.操作')"
              fixed="right"
              width="230"
            >
              <template #default="scope">
                <el-button
                  type="success"
                  size="small"
                  @click="OpenGoodsWindow(scope.row)"
                >
                  {{ tools.GetLanguageValue("web.编辑") }}</el-button
                >
                <el-button
                  size="small"
                  @click="GoodsDelete(scope.row)"
                  type="danger"
                >
                  {{ tools.GetLanguageValue("web.删除") }}</el-button
                >
                <el-button
                  size="small"
                  @click="OpenGenerateGoodsProductSkuWindow(scope.row)"
                  type="primary"
                >
                  SKU</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <div
            style="
              margin: 5px;
              height: 50px;
              line-height: 40px;
              display: flex;
              align-items: center;
            "
          >
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="
                (s) => {
                  GoodsPadding.Page = s;
                  GetGoods();
                }
              "
              :page-size="GoodsPadding.Size"
              :page-sizes="[30, 50, 100]"
              :size-change="
                (s) => {
                  GoodsPadding.Size = s;
                  GetGoods();
                }
              "
              :total="GoodsPadding.Count"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </navigation>

  <!-- 添加 -->
  <el-dialog
    v-model="GoodsWindow"
    title="商品详细"
    width="70%"
    :show-close="false"
  >
    <el-form
      ref="form"
      :model="GoodsOpt"
      label-width="120px"
      style="margin: 10px 0px"
    >
      <el-tabs v-model="LanguageName">
        <el-tab-pane :label="tools.GetLanguageValue('web.默认')" name="first">
          <el-row>
            <el-col :span="12">
              <el-form-item :label="tools.GetLanguageValue('web.商品名称')">
                <el-input
                  v-model="GoodsOpt.Name"
                  @focus="InputSelect"
                ></el-input>
              </el-form-item>

              <el-form-item :label="tools.GetLanguageValue('web.助记码')">
                <el-input
                  @focus="InputSelect"
                  v-model="GoodsOpt.MnemonicCode"
                ></el-input>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.编号')">
                <el-input
                  @focus="InputSelect"
                  v-model="GoodsOpt.Code"
                ></el-input>
              </el-form-item>
              <el-form-item label="条形码">
                <el-input
                  @focus="InputSelect"
                  v-model="GoodsOpt.BarCode"
                ></el-input>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.商品类型')">
                <el-select
                  :placeholder="tools.GetLanguageValue('web.请选择商品类型')"
                  v-model="GoodsOpt.ProductType"
                >
                  <el-option
                    v-for="(item, index) in ProductTypes"
                    :key="index"
                    :label="item.Value"
                    :value="item.Key"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item :label="tools.GetLanguageValue('web.分类')">
                <el-select
                  :placeholder="tools.GetLanguageValue('web.请选择分类')"
                  v-model="GoodsOpt.GoodsCategoryId"
                  @change="ChangeCategory"
                >
                  <el-option
                    :label="tools.GetLanguageValue('web.请选择品牌')"
                    value="00000000-0000-0000-0000-000000000000"
                  >
                    {{ tools.GetLanguageValue("web.请选择分类") }}
                  </el-option>
                  <el-option
                    v-for="(item, index) in Categorys"
                    :key="index"
                    :label="item.Name"
                    :value="item.Id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.品牌')">
                <el-select
                  v-model="GoodsOpt.BrandId"
                  :placeholder="tools.GetLanguageValue('web.请选择品牌')"
                >
                  <el-option
                    :label="tools.GetLanguageValue('web.请选择品牌')"
                    value="00000000-0000-0000-0000-000000000000"
                  >
                    {{ tools.GetLanguageValue("web.请选择品牌") }}
                  </el-option>
                  <el-option
                    v-for="item in BrandDatas"
                    :key="item.Id"
                    :label="item.Name"
                    :value="item.Id"
                  >
                    {{ item.Name }}
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item :label="tools.GetLanguageValue('web.单价')">
                <el-input-number
                  @focus="InputSelect"
                  v-model="GoodsOpt.Price"
                ></el-input-number>
              </el-form-item>

              <el-form-item :label="tools.GetLanguageValue('web.成本价')">
                <el-input-number
                  @focus="InputSelect"
                  v-model="GoodsOpt.Cost"
                ></el-input-number>
              </el-form-item>

              <el-form-item :label="tools.GetLanguageValue('web.可用销售价')">
                <div
                  style="width: 100%; display: flex; justify-items: flex-start"
                >
                  <span
                    v-for="(priceItem, index) in GoodsOpt.PrecioDeVentas"
                    :key="index"
                  >
                    <el-tag
                      v-if="
                        priceItem.Id == '00000000-0000-0000-0000-000000000000'
                      "
                      @close="DeletePrecioDeVentaByArrey(priceItem)"
                      closable
                      style="margin: 5px"
                    >
                      {{ priceItem.Price }}
                    </el-tag>
                    <el-tag
                      style="margin: 5px"
                      v-else
                      @close="DeletePrecioDeVenta(priceItem)"
                      type="danger"
                      closable
                    >
                      {{ priceItem.Price }}
                    </el-tag>
                  </span>
                </div>

                <div style="display: flex">
                  <el-input
                    @focus="InputSelect"
                    v-model="PrecioDeVentaVal"
                    style="width: 70px; margin: 5px"
                  />
                  <el-button
                    @click="AddPrecioDeVentaVal(GoodsOpt)"
                    style="margin: 5px"
                  >
                    {{ tools.GetLanguageValue("web.新增") }}</el-button
                  >
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="tools.GetLanguageValue('web.原价')">
                <el-input-number
                  @focus="InputSelect"
                  v-model="GoodsOpt.OldPrice"
                ></el-input-number>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.排序')">
                <el-input-number
                  @focus="InputSelect"
                  v-model="GoodsOpt.Sort"
                ></el-input-number>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.是否含税')">
                <el-switch
                  v-model="GoodsOpt.IncludeTax"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.税率')">
                <el-input-number
                  @focus="InputSelect"
                  v-model="GoodsOpt.TaxRate"
                ></el-input-number>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.备注')">
                <el-input
                  @focus="InputSelect"
                  v-model="GoodsOpt.Description"
                ></el-input>
              </el-form-item>

              <el-form-item
                :label="item.Name"
                v-for="item in AttributeGroup"
                :key="item.Id"
              >
                <el-radio-group v-model="item.OptProductAttribute">
                  <el-radio
                    v-for="attr in item.ProductAttribute"
                    :key="attr.Id"
                    v-model="attr.Id"
                    :label="attr.Id"
                    size="small"
                    style="margin: 5px"
                    border
                  >
                    {{ attr.Name }}</el-radio
                  >
                  <el-icon
                    size="19"
                    style="margin-left: 15px"
                    @click="OpenAttributeGroupItemWindow(item)"
                    ><EditPen
                  /></el-icon>
                </el-radio-group>
              </el-form-item>
              <!-- <el-form-item :label="tools.GetLanguageValue('web.封面图')">
                <el-upload
                  class="avatar-uploader"
                  :action="UploadPictureImageUrl"
                  :show-file-list="false"
                  :on-success="UploadSuccess"
                  :before-upload="UploadSuccess"
                  :headers="headersImage"
                >
                  <img
                    v-if="GoodsOpt.ImagesFormat"
                    :src="GoodsOpt.ImagesFormat"
                    class="avatar"
                  />
                  <el-icon v-else class="avatar-uploader-icon"
                    ><Plus
                  /></el-icon>
                </el-upload>
              </el-form-item> -->
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane
          :label="item.LanguageName"
          :name="item.LanguageName"
          v-for="(item, index) in GoodsOpt.Languages"
          :key="index"
        >
          <el-form-item :label="tools.GetLanguageValue('web.商品名称')">
            <el-input
              v-model="item.Language.Name"
              @focus="InputSelect"
            ></el-input>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="GoodsWindow = false">
          {{ tools.GetLanguageValue("web.关闭") }}</el-button
        >
        <el-button @click="InsertGoods" type="primary">
          {{ tools.GetLanguageValue("web.保存") }}</el-button
        >
      </span>
    </template>
  </el-dialog>

  <!-- 添加 -->
  <el-dialog
    v-model="GenerateGoodsProductSkuWindow"
    :title="tools.GetLanguageValue('web.生成SKU')"
    :fullscreen="true"
    :show-close="true"
  >
    <div
      style="height: 700px; overflow: auto"
      :style="{ height: clientHeight - 165 + 'px' }"
    >
      <el-card class="box-card" style="margin-top: 20px">
        <div class="clearfix">
          <span> {{ tools.GetLanguageValue("web.选择属性") }}</span>
        </div>
        <span
          style="margin: 7px"
          v-for="item in AttributeGroup"
          :key="item.Id"
          class="text item"
        >
          <el-checkbox
            v-model="item.IsCheckd"
            :label="item.Name"
            border
          ></el-checkbox>
        </span>
      </el-card>

      <el-card class="box-card" style="margin-top: 20px">
        <div class="clearfix">
          <span> {{ tools.GetLanguageValue("web.生成项") }}</span>
        </div>
        <el-checkbox-group v-model="CheckboxProductAttribute" size="mini">
          <div
            style="margin: 7px"
            v-for="item in AttributeGroup"
            :key="item.Id"
            class="text item"
            v-show="item.IsCheckd"
          >
            <el-card class="box-card" style="margin-top: 20px">
              <span>
                <el-checkbox
                  v-for="attr in item.ProductAttribute"
                  :key="attr.Id"
                  v-model="attr.Id"
                  :label="attr"
                  border
                >
                  {{ attr.Name }}
                </el-checkbox>
              </span>
            </el-card>
          </div>
        </el-checkbox-group>

        <div style="width: 100%; text-align: center">
          <el-button
            @click="GenerateGoodsProductSku"
            type="primary"
            style="width: 150px; margin: 0 auto; text-align: center"
          >
            {{ tools.GetLanguageValue("web.生成SKU") }}</el-button
          >
        </div>
      </el-card>

      <el-card
        class="box-card"
        style="margin-top: 20px"
        v-if="ProductSKU.length > 0"
      >
        <div class="clearfix">
          <span> {{ tools.GetLanguageValue("web.需要生成") }}</span>
        </div>
        <div>
          <table style="width: 100%" border="1" class="tableSKU">
            <tr>
              <th style="width: 220px">
                {{ tools.GetLanguageValue("web.名称") }}
              </th>
              <th width="200">{{ tools.GetLanguageValue("web.属性") }}</th>
              <th width="100">{{ tools.GetLanguageValue("web.编码") }}</th>
              <th width="80">{{ tools.GetLanguageValue("web.成本价") }}</th>
              <th width="80">{{ tools.GetLanguageValue("web.助记码") }}</th>
              <th width="80">{{ tools.GetLanguageValue("web.单价") }}</th>
              <th width="80">{{ tools.GetLanguageValue("web.税率") }}</th>
              <th width="80">{{ tools.GetLanguageValue("web.默认SKU") }}</th>
              <th>{{ tools.GetLanguageValue("web.备注") }}</th>
              <th>{{ tools.GetLanguageValue("web.操作") }}</th>
            </tr>

            <tr v-for="item in ProductSKU" :key="item.Id" style="padding: 5px">
              <td style="margin: 5px">{{ item.Name }}</td>
              <td style="margin: 5px">{{ item.SkuAttributeFormat }}</td>
              <td style="margin: 5px">{{ item.Code }}</td>
              <td style="margin: 5px">
                <input v-model="item.Cost" class="inputSKU" />
              </td>
              <td style="margin: 5px">
                <input v-model="item.MnemonicCode" class="inputSKU" />
              </td>
              <td style="margin: 5px">
                <input v-model="item.Price" class="inputSKU" />
              </td>
              <td style="margin: 5px">
                <input v-model="item.TaxRate" class="inputSKU" />
              </td>
              <td style="margin: 5px">
                <el-switch
                  v-model="item.DefaultSku"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </td>
              <td style="margin: 5px">
                <input v-model="item.Description" class="inputSKU" />
              </td>
              <td style="margin: 5px">
                <button @click="DeleteCreateSku(item)">
                  {{ tools.GetLanguageValue("web.删除") }}
                </button>
              </td>
            </tr>
          </table>

          <el-button
            @click="BatchInsertProduct"
            type="primary"
            style="
              width: 150px;
              height: 40px;
              margin: 0 auto;
              text-align: center;
              margin: 10px 0px;
            "
          >
            {{ tools.GetLanguageValue("web.保存") }}</el-button
          >
        </div>
      </el-card>

      <el-card class="box-card" style="margin-top: 20px">
        <div class="clearfix" style="margin: 20px 0px">
          <span style="font-size: 17pt">
            {{ tools.GetLanguageValue("web.现有商品") }}</span
          >
        </div>
        <div>
          <div>
            <table style="width: 100%" border="1" class="tableSKU">
              <tr>
                <th style="width: 220px">
                  {{ tools.GetLanguageValue("web.名称") }}
                </th>
                <th width="200">{{ tools.GetLanguageValue("web.属性") }}</th>
                <th width="100">{{ tools.GetLanguageValue("web.编码") }}</th>
                <th width="80">{{ tools.GetLanguageValue("web.成本价") }}</th>
                <th width="80">{{ tools.GetLanguageValue("web.助记码") }}</th>
                <th width="80">{{ tools.GetLanguageValue("web.税率") }}</th>
                <th width="80">{{ tools.GetLanguageValue("web.单价") }}</th>
                <th width="80">{{ tools.GetLanguageValue("web.排序") }}</th>
                <th>{{ tools.GetLanguageValue("web.备注") }}</th>
                <th width="330">{{ tools.GetLanguageValue("web.操作") }}</th>
              </tr>

              <tr
                v-for="item in GoodsPrducts"
                :key="item.Id"
                style="padding: 5px"
              >
                <td style="margin: 5px">
                  <span v-if="item.DefaultSku"
                    >({{ tools.GetLanguageValue("web.默认") }})</span
                  >{{ item.Name }}
                </td>
                <td style="margin: 5px">{{ item.SkuAttributeFormat }}</td>
                <td style="margin: 5px">
                  {{ item.Code }}
                </td>
                <td style="margin: 5px">
                  {{ item.Cost }}
                </td>
                <td style="margin: 5px">
                  {{ item.MnemonicCode }}
                </td>
                <td style="margin: 5px">
                  {{ item.TaxRate }}
                </td>
                <td style="margin: 5px">
                  {{ item.Price }}
                </td>

                <td style="margin: 5px">
                  {{ item.Sort }}
                </td>

                <td style="margin: 5px">
                  {{ item.Description }}
                </td>
                <td style="margin: 5px">
                  <el-button
                    @click="SettingProductDefaultSku(item)"
                    type="warning"
                    size="small"
                    >{{ tools.GetLanguageValue("web.设为默认") }}</el-button
                  >

                  <el-button
                    type="success"
                    size="small"
                    @click="OpenProductSkuEditWindow(item)"
                    >{{ tools.GetLanguageValue("web.编辑") }}</el-button
                  >
                  <el-button
                    @click="DeleteProducSKU(item)"
                    type="danger"
                    size="small"
                    >{{ tools.GetLanguageValue("web.删除") }}
                  </el-button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </el-card>
    </div>

    <template #footer>
      <hr />
      <span class="dialog-footer">
        <el-button
          type="primary"
          @click="GenerateGoodsProductSkuWindow = false"
        >
          {{ tools.GetLanguageValue("web.关闭") }}</el-button
        >
      </span>
    </template>
  </el-dialog>

  <!-- 修改 SKU-->
  <el-dialog
    v-model="ProductSkuEditWindow"
    :title="tools.GetLanguageValue('web.修改SKU')"
    :show-close="true"
    width="70%"
  >
    <el-form style="margin-top: 15px" label-width="120px">
      <el-row>
        <el-col :span="12">
          <el-form-item :label="tools.GetLanguageValue('web.商品名称')">
            <el-input v-model="GoodsPrductOpt.Name"></el-input>
          </el-form-item>

          <el-form-item :label="tools.GetLanguageValue('web.助记码')">
            <el-input v-model="GoodsPrductOpt.MnemonicCode"></el-input>
          </el-form-item>
          <el-form-item :label="tools.GetLanguageValue('web.编号')">
            <el-input v-model="GoodsPrductOpt.Code"></el-input>
          </el-form-item>

          <el-form-item :label="tools.GetLanguageValue('web.选择属性')">
            <span
              style="margin: 7px"
              v-for="item in AttributeGroup"
              :key="item.Id"
              class="text item"
            >
              {{ item.Name }}

              <el-radio-group v-model="item.OptProductAttribute">
                <el-radio
                  v-for="attr in item.ProductAttribute"
                  :key="attr.Id"
                  v-model="attr.Id"
                  :label="attr.Id"
                  size="mini"
                  style="margin: 5px"
                  border
                >
                  {{ attr.Name }}</el-radio
                >
                <el-button
                  @click="item.OptProductAttribute = ''"
                  type="danger"
                  circle
                >
                  <el-icon><Delete /></el-icon>
                </el-button>
              </el-radio-group>
            </span>
          </el-form-item>

          <el-form-item :label="tools.GetLanguageValue('web.单价')">
            <el-input-number v-model="GoodsPrductOpt.Price"></el-input-number>
          </el-form-item>

          <el-form-item :label="tools.GetLanguageValue('web.成本价')">
            <el-input-number v-model="GoodsPrductOpt.Cost"></el-input-number>
          </el-form-item>

          <el-form-item :label="tools.GetLanguageValue('web.可用销售价')">
            <div style="width: 100%; display: flex; justify-items: flex-start">
              <span
                v-for="(priceItem, index) in GoodsPrductOpt.PrecioDeVentas"
                :key="index"
              >
                <el-tag
                  v-if="priceItem.Id == '00000000-0000-0000-0000-000000000000'"
                  @close="DeletePrecioDeVentaByArrey(priceItem)"
                  closable
                  style="margin: 5px"
                >
                  {{ priceItem.Price }}
                </el-tag>
                <el-tag
                  style="margin: 5px"
                  v-else
                  @close="DeletePrecioDeVenta(priceItem)"
                  type="danger"
                  closable
                >
                  {{ priceItem.Price }}
                </el-tag>
              </span>
            </div>

            <div style="display: flex">
              <el-input
                v-model="PrecioDeVentaVal"
                style="width: 70px; margin: 5px"
              />
              <el-button
                @click="AddPrecioDeVentaVal(GoodsPrductOpt)"
                style="margin: 5px"
              >
                {{ tools.GetLanguageValue("web.新增") }}</el-button
              >
            </div>
          </el-form-item>

          <el-form-item :label="tools.GetLanguageValue('web.原价')">
            <el-input-number
              v-model="GoodsPrductOpt.OldPrice"
            ></el-input-number>
          </el-form-item>
          <el-form-item :label="tools.GetLanguageValue('web.排序')">
            <el-input-number v-model="GoodsPrductOpt.Sort"></el-input-number>
          </el-form-item>
          <el-form-item :label="tools.GetLanguageValue('web.是否含税')">
            <el-switch
              v-model="GoodsPrductOpt.IncludeTax"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
          <el-form-item :label="tools.GetLanguageValue('web.税率')">
            <el-input-number v-model="GoodsPrductOpt.TaxRate"></el-input-number>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item :label="tools.GetLanguageValue('web.备注')">
            <el-input v-model="GoodsPrductOpt.Description"></el-input>
          </el-form-item>
          <el-form-item :label="tools.GetLanguageValue('web.封面图')">
            <el-upload
              class="avatar-uploader"
              :action="UploadPictureImageUrl"
              :show-file-list="false"
              :on-success="UploadProductSuccess"
              :before-upload="UploadProductSuccess"
              :headers="headersImage"
            >
              <img
                v-if="GoodsPrductOpt.ImagesFormat"
                :src="GoodsPrductOpt.ImagesFormat"
                class="avatar"
              />
              <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
            </el-upload>
          </el-form-item>
          <!-- 
          <el-form-item label="滑动图"> </el-form-item> -->
          <el-form-item>
            <el-upload
              class="avatar-uploader"
              :action="UploadFullImageFileUrl"
              :show-file-list="false"
              :on-success="UploadProductPicterSuccess"
              :before-upload="UploadProductPicterSuccess"
              :headers="headersImage"
            >
              <el-icon class="avatar-uploader-icon"><Plus /></el-icon>
            </el-upload>
            <div
              style="
                width: 120px;
                height: 120px;
                border: 1px solid #eee;
                margin: 5px 5px;
              "
              v-for="(item, index) in GoodsPrductOpt.PictureList"
              :key="index"
            >
              <img
                style="width: 110px; height: 110px"
                :src="item.CompleteImageFormet"
                class="avatar"
                @click="ViewLargeImage(item)"
              />
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="ProductSkuEditWindow = false">
          {{ tools.GetLanguageValue("web.关闭") }}</el-button
        >
        <el-button @click="UpdateProduct" type="primary">
          {{ tools.GetLanguageValue("web.保存") }}</el-button
        >
      </span>
    </template>
  </el-dialog>

  <!-- 列表的大图 -->
  <el-dialog
    title="大图  tools.GetLanguageValue('web.关闭')  "
    v-model="ImageListDetailWindow"
    style="width: 1200px; text-align: center"
  >
    <div style="">
      <img :src="ImageListDetailUrl" style="width: 1000px" />
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="ImageListDetailWindow = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 编辑的大图 -->
  <el-dialog
    title="大图  tools.GetLanguageValue('web.关闭')  "
    v-model="ImageEditWindow"
    style="text-align: center"
  >
    <img :src="PictureOpt.CompleteImageFormet" style="width: 1000px" />

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="DeletePicture" type="primary">删除</el-button>
        <el-button @click="ImageEditWindow = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 添加属性小项 -->
  <el-dialog
    v-model="AttributeItemWindow"
    :title="tools.GetLanguageValue('web.属性')"
    width="70%"
    :show-close="false"
  >
    <el-row style="margin: 5px">
      <el-col :span="15">
        <el-table border :data="AttributeGroupItemList" height="500px">
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column
            :label="tools.GetLanguageValue('web.属性名称')"
            width="180"
            prop="Name"
          />

          <el-table-column
            :label="tools.GetLanguageValue('web.排序')"
            width="80"
            prop="Sort"
          />
          <el-table-column
            :label="tools.GetLanguageValue('web.备注')"
            width="180"
            prop="Desc"
          />
          BrandName
          <el-table-column
            :label="tools.GetLanguageValue('web.操作')"
            fixed="right"
            width="200"
          >
            <template #default="scope">
              <el-button
                type="success"
                size="small"
                @click="OpenttributeItemWindow(scope.row)"
              >
                {{ tools.GetLanguageValue("web.编辑") }}</el-button
              >

              <el-button
                size="small"
                @click="DeleteAttributeItem(scope.row)"
                type="danger"
              >
                {{ tools.GetLanguageValue("web.删除") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="8">
        <div style="width: 100%; height: 300px; padding: 15px">
          <el-form
            ref="form"
            label-width="120px"
            style="margin: 15px"
            v-model="AttributeGroupItemModel"
            :model="AttributeGroupItemModel"
            :rules="AttributeGroupRules"
          >
            <el-form-item
              :label="tools.GetLanguageValue('web.项名称')"
              prop="Name"
            >
              <el-input
                @focus="InputSelect($event)"
                v-model="AttributeGroupItemModel.Name"
                :placeholder="tools.GetLanguageValue('web.项名称')"
              ></el-input>
            </el-form-item>

            <el-form-item
              :label="tools.GetLanguageValue('web.排序')"
              prop="Sort"
            >
              <el-input-number
                v-model="AttributeGroupItemModel.Sort"
                :placeholder="tools.GetLanguageValue('web.请输入排序')"
                @focus="InputSelect($event)"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              :label="tools.GetLanguageValue('web.备注')"
              prop="Name"
            >
              <el-input
                v-model="AttributeGroupItemModel.Desc"
                :placeholder="tools.GetLanguageValue('web.请输入备注')"
                @focus="InputSelect($event)"
              ></el-input>
            </el-form-item>
          </el-form>
          <div style="width: 100%; text-align: center">
            <el-button
              type="primary"
              style="width: 150px; height: 40px"
              @click="InsertAttributeGroupItem"
            >
              {{ tools.GetLanguageValue("web.保存") }}</el-button
            >
          </div>
        </div>
      </el-col>
    </el-row>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="AttributeItemWindow = !AttributeItemWindow">
          {{ tools.GetLanguageValue("web.关闭") }}</el-button
        >
      </span>
    </template>
  </el-dialog>

  <!-- 临时商品设置 -->
  <el-dialog
    v-model="StoreSettingsWindow"
    :title="tools.GetLanguageValue('web.设置')"
    :show-close="false"
  >
    <el-form label-width="200px" style="margin: 10px 0px">
      <el-divider content-position="left">{{
        tools.GetLanguageValue("web.税率")
      }}</el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="tools.GetLanguageValue('web.默认临时商品税率')">
            <el-input-number
              v-model="StoreSettings.DefaultTaxRate"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="tools.GetLanguageValue('web.临时商品名称')">
            <el-input v-model="StoreSettings.TempProductName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button @click="UpdateMarketInfo" type="danger">{{
              tools.GetLanguageValue("web.保存")
            }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <span class="dialog-footer"> </span>
    </template>
  </el-dialog>
</template>
<script >
import axios from "../../../../commons/AxiosMethod.js";
import tools from "../../../../commons/tools.js";
import { ElMessageBox, ElMessage, UploadProps } from "element-plus";
import ProductItem from "../../../../components/ProductItem.vue";
import navigation from "../../../../components/Modules/Navigation.vue";
import PictureCover from "../../../../components/PictureCover.vue";
import {
  CloseBold,
  Plus,
  Delete,
  List,
  Apple,
  Help,
  EditPen,
  Coin,
} from "@element-plus/icons-vue";

export default {
  // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types aboutus
  data() {
    return {
      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight,

      ImageListDetailWindow: false, //列表图片详细
      ImageListDetailUrl: false,

      PictureOpt: "", //当前选择的图片
      ImageEditWindow: false,

      tools: tools,
      AttributeItemWindow: false,
      GenerateGoodsProductSkuWindow: false,
      PrecioDeVentaVal: 0, //销售价
      GoodsOpt: {
        PrecioDeVentas: [],
      },
      GoodsWindow: false,
      GoodsDatas: [],
      GoodsPadding: {
        Page: 1,
        Size: 30,
        Count: 0,
        Where: {},
      },
      GoodsPictureOpt: {
        ImageFormet: "",
        Images: "",
      }, //图片
      Categorys: [],
      ProductTypes: [],

      AttributeGroup: [], //属性组

      CheckboxProductAttribute: [],
      LanguageName: "first",
      ProductSKU: [], //所包含的所有SKU 是生成的 ，还没有保存

      GoodsPrducts: [],
      GoodsPrductOpt: {
        PictureList: [],
      },

      BrandDatas: [],
      ContentHeight: 0,
      ProductSkuEditWindow: false, //sku编辑

      headersImage: {},
      UploadPictureImageUrl: "",
      UploadFullImageFileUrl: "", //完整上传地址

      GoodsSearch: "",

      AttributeGroupItemModel: {}, //当前需要快速添加的属性组
      AttributeGroupItemList: [],

      SearchProductTypes: [],

      StoreSettingsWindow: false,
      StoreSettings: {},
    };
  },
  name: "App",
  mounted() {
    this.GetGoods();
    this.GetBrands();
    this.GetCategoryAll();
    this.GetProductType();
    this.GetAttributeGroupOrItemAll();
    this.GetSettings();

    this.UploadPictureImageUrl = axios.GetUrl() + "/api/Upload/ImageFileWebp";
    this.UploadFullImageFileUrl =
      axios.GetUrl() + "/api/Upload/FullImageFileWebp";

    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header"),
    };

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
        this.TableHeight = document.documentElement.clientHeight - 140;
        this.ContentHeight = document.documentElement.clientHeight - 120;
        this.$refs.navigation.onresize();
      })();
    };
    this.TableHeight = document.documentElement.clientHeight - 140;
    this.ContentHeight = document.documentElement.clientHeight - 120;
    this.clientHeight = document.documentElement.clientHeight;
  },

  components: {
    navigation,
    ProductItem,
    PictureCover,
    CloseBold,
    List,
    Coin,
    EditPen,
    Plus,
    Apple,
    Help,
    Delete,
  },
  methods: {
    InputSelect(e) {
      e.currentTarget.select();
    },
    //保存销售相关的配置
    UpdateMarketInfo() {
      axios.apiMethod(
        "/stores/Setting/UpdateMarketInfo",
        "post",
        this.StoreSettings,
        (result) => {
          if (result.Data.IsSuccess) {
            this.StoreSettingsWindow = false;
            ElMessage({
              message: tools.GetLanguageValue("web.操作成功"),
              type: "success",
            });
          } else {
            ElMessage({
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },
    //获取配置
    GetSettings() {
      axios.apiMethod(
        "/stores/Setting/GetStoreSettings",
        "get",
        {},
        (result) => {
          this.StoreSettings = result.Data;
        }
      );
    },
    //删除属性项目
    DeleteAttributeItem(item) {
      ElMessageBox.confirm(
        "确定要删除<span style='color:red;font-size:14pt'>[" +
          item.Name +
          "]</span>吗" +
          "?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          axios.apiMethod(
            "/stores/GoodsManage/DeletAttributeById",
            "get",
            { id: item.Id },
            (result) => {
              this.AttributeItemWindow = false;
              this.GetAttributeGroupOrItemAll(0);
            }
          );
        })
        .catch(() => {
          // catch error
        });
    },

    //编辑属性详细
    OpenttributeItemWindow(item) {
      this.AttributeGroupItemModel = item;
    },
    OpenAttributeGroupItemWindow(item) {
      this.AttributeGroupItemModel = {
        Sort: 0,
        Name: "",
        Desc: "",
        GroupId: item.Id,
      };
      this.AttributeGroupItemList = item.ProductAttribute;
      this.AttributeItemWindow = true;
    },
    //插入属性
    InsertAttributeGroupItem() {
      axios.apiMethod(
        "/stores/GoodsManage/InsertAttributeList",
        "post",
        this.AttributeGroupItemModel,
        (result) => {
          if (result.Data.IsSuccess) {
            this.AttributeItemWindow = false;
            this.GetAttributeGroupOrItemAll(0);
          } else {
            ElMessage({
              message: result.Data.Msg,
              type: "success",
            });
          }
        }
      );
    },
    GoPage(url) {
      this.$router.push({
        path: url,
        query: {
          s: Date.now(),
        },
      });
    },

    //删除图片
    DeletePicture() {
      axios.apiMethod(
        "/stores/GoodsManage/DeletePicture",
        "post",
        {
          Id: this.PictureOpt.Id,
        },
        (result) => {
          this.GetPictureByProductId();
          this.ImageEditWindow = false;
        }
      );
    },
    ViewLargeImage(item) {
      this.ImageEditWindow = true;
      this.PictureOpt = item;
    },

    //修改商品SKU
    UpdateProduct() {
      this.GoodsPrductOpt.SkuAttribute = [];
      this.AttributeGroup.forEach((s) => {
        if (s.OptProductAttribute != "")
          this.GoodsPrductOpt.SkuAttribute.push({ Id: s.OptProductAttribute });
      });

      axios.apiMethod(
        "/stores/GoodsManage/UpdateProduct",
        "post",
        this.GoodsPrductOpt,
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetProductByGoodsId();
            this.ProductSkuEditWindow = false;
            ElMessageBox({
              title: "提示",
              message: "操作成功",
              type: "success",
            });
          } else {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },
    //上传列表图
    UploadProductPicterSuccess(response, file, fileList) {
      if (response.Code == 200) {
        this.ComImageUrl = response.Data.FileName;
        this.ComImageFormet = response.Data.ImageFormet;

        axios.apiMethod(
          "/stores/GoodsManage/InsertProductPicture",
          "post",
          {
            ProductId: this.GoodsPrductOpt.ProductId,
            CompleteImage: response.Data.FileName,
            ThumbnailImage: response.Data.FileName,
          },
          (result) => {
            this.GetPictureByProductId();
          }
        );
      }
    },
    //获取SKU的图片
    GetPictureByProductId() {
      axios.apiMethod(
        "/stores/GoodsManage/GetPictureByProductId?productId=" +
          this.GoodsPrductOpt.ProductId,
        "get",
        {},
        (result) => {
          this.GoodsPrductOpt.PictureList = result.Data;
        }
      );
    },
    UploadProductSuccess(response, file, fileList) {
      if (response.Code == 200) {
        this.ComImageUrl = response.Data.FileName;
        this.ComImageFormet = response.Data.ImageFormet;
        this.GoodsPrductOpt.ImagesFormat = response.Data.ImageFormet;
        this.GoodsPrductOpt.Images = response.Data.FileName;
      }
    },

    // 上传成功操作
    UploadSuccess(response, file, fileList) {
      if (response.Code == 200) {
        this.ComImageUrl = response.Data.FileName;
        this.ComImageFormet = response.Data.ImageFormet;
        this.GoodsOpt.ImagesFormat = response.Data.ImageFormet;
        this.GoodsOpt.Images = response.Data.FileName;
      }
    },

    // 编辑SKU
    OpenProductSkuEditWindow(item) {
      axios.apiMethod(
        "/stores/GoodsManage/GetProductByIdDetail?productId=" + item.Id,
        "get",
        {},
        (result) => {
          this.GoodsPrductOpt = result.Data;
          this.GetPictureByProductId();
          this.GetSKUAttributeGroupOrItemAll();
          this.ProductSkuEditWindow = true;
        }
      );
    },

    //删除未保存的阶梯价
    DeletePrecioDeVentaByArrey(item) {
      for (var i = 0; this.GoodsOpt.PrecioDeVentas.length > i; i++) {
        if (this.GoodsOpt.PrecioDeVentas[i].Price == item.Price) {
          this.GoodsOpt.PrecioDeVentas.splice(i, 1);

          return;
        }
      }
    },

    //删除阶梯价
    DeletePrecioDeVenta(item) {
      axios.apiMethod(
        "/stores/Goods/DeletePrecioDeVenta?precioDeVentaId=" + item.Id,
        "get",
        {},
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetGoodsByIdDetail(this.GoodsOpt);
          }
        }
      );
    },
    //添加
    AddPrecioDeVentaVal(item) {
      if (this.PrecioDeVentaVal > 0) {
        for (var i = 0; item.PrecioDeVentas.length > i; i++) {
          if (item.PrecioDeVentas[i].Price == this.PrecioDeVentaVal) {
            this.PrecioDeVentaVal = 0;

            return;
          }
        }
        item.PrecioDeVentas.push({
          Price: this.PrecioDeVentaVal,
          Id: "00000000-0000-0000-0000-000000000000",
        });
        this.PrecioDeVentaVal = 0;
      }
    },
    ///删除带创建的SKU
    DeleteCreateSku(item) {
      var index = this.ProductSKU.indexOf(item);

      if (index > -1) {
        this.ProductSKU.splice(index, 1);
      }
    },
    //获取品牌
    GetBrands() {
      axios.apiMethod("/stores/Data/GetProductBrands", "post", {}, (result) => {
        this.BrandDatas = result.Data;
      });
    },
    //查看详细
    DetailProducSKU() {},
    //插入或者修改SKU
    UpdateProducSKU(item) {
      axios.apiMethod(
        "/stores/GoodsManage/UpdateProducSKU",
        "post",
        item,
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetProductByGoodsId();

            ElMessageBox({
              title: "提示",
              message: "操作成功",
              type: "success",
            });
          } else {
            ElMessageBox({
              title: "提示",
              message: "修改失败",
              type: "error",
            });
          }
        }
      );
    },
    //删除SKU
    DeleteProducSKU(item) {
      ElMessageBox.confirm(
        "确定要删除<span style='color:red;font-size:14pt'>[" +
          item.Name +
          "," +
          item.SkuAttributeFormat +
          "]</span>吗" +
          "?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          axios.apiMethod(
            "/stores/GoodsManage/DeleteProducSKU",
            "post",
            item,
            (result) => {
              if (result.Data.IsSuccess) {
                this.GetProductByGoodsId();
              } else {
                ElMessageBox({
                  title: "提示",
                  message: "设置失败",
                  type: "error",
                });
              }
            }
          );
        })
        .catch(() => {});
    },
    //设置默认SKU
    SettingProductDefaultSku(item) {
      axios.apiMethod(
        "/stores/GoodsManage/SettingProductDefaultSku",
        "post",
        item,
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetProductByGoodsId();
          } else {
            ElMessageBox({
              title: "提示",
              message: "设置失败",
              type: "error",
            });
          }
        }
      );
    },
    // 通过商品获取所有SKU
    GetProductByGoodsId() {
      axios.apiMethod(
        "/stores/GoodsManage/GetProductByGoodsId",
        "get",
        { Id: this.GoodsOpt.Id },
        (result) => {
          this.GoodsPrducts = result.Data;
        }
      );
    },
    //批量插入SKU
    BatchInsertProduct() {
      axios.apiMethod(
        "/stores/GoodsManage/BatchInsertProduct",
        "post",
        { Id: this.GoodsOpt.Id, Products: this.ProductSKU },
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetProductByGoodsId();
          } else {
            ElMessageBox({
              title: "提示",
              message: "插入失败",
              type: "error",
            });
          }
        }
      );
    },
    //创建SKU
    GenerateGoodsProductSku() {
      if (this.CheckboxProductAttribute.length == 0) {
        ElMessageBox({
          title: "提示",
          message: "请选择需要生成的属性",
          type: "error",
        });
        return;
      }

      ElMessageBox.confirm(
        "<span style='color:red'> 已存在的SKU不会修改！ </span>",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          axios.apiMethod(
            "/stores/GoodsManage/GenerateGoodsProductSku",
            "post",
            {
              GoodsId: this.GoodsOpt.Id,
              Attributes: this.CheckboxProductAttribute,
            },
            (result) => {
              this.ProductSKU = result.Data.Model;
            }
          );
        })
        .catch(() => {
          // catch error
        });
    },
    //打开生成SKU页面
    OpenGenerateGoodsProductSkuWindow(item) {
      this.GoodsOpt = item;
      this.GenerateGoodsProductSkuWindow = true;
      this.GetProductByGoodsId();
    },

    ChangeCategory(categoryId) {
      for (var i = 0; i < this.Categorys.length; i++) {
        if (this.Categorys[i].Id == categoryId) {
          if (this.Categorys[i].TaxRate > 0) {
            this.GoodsOpt.TaxRate = this.Categorys[i].TaxRate;
            ElMessage({
              title: "提示",
              message: "税率已更改为" + this.GoodsOpt.TaxRate,
              type: "success",
            });
          }
        }
      }
    },

    //获取SKU所有属性
    GetSKUAttributeGroupOrItemAll() {
      axios.apiMethod(
        "/stores/GoodsManage/GetAttributeGroupOrItemAll",
        "post",
        {
          GoodsId: this.GoodsPrductOpt.Id,
          BrandId: this.GoodsPrductOpt.BrandId,
          CategoryId: this.GoodsPrductOpt.GoodsCategoryId,
        },
        (result) => {
          this.AttributeGroup = result.Data;

          this.AttributeGroup.forEach((group) => {
            for (var i = 0; i < group.ProductAttribute.length; i++) {
              this.GoodsPrductOpt.SkuAttribute.forEach((sku) => {
                if (group.ProductAttribute[i].Id == sku.AttributeId) {
                  group.OptProductAttribute = group.ProductAttribute[i].Id;
                }
              });
            }
          });
        }
      );
    },

    //获取所有属性
    GetAttributeGroupOrItemAll(goodsId) {
      axios.apiMethod(
        "/stores/GoodsManage/GetAttributeGroupOrItemAll",
        "post",
        {
          GoodsId: this.GoodsOpt.Id,
          BrandId: this.GoodsOpt.BrandId,
          CategoryId: this.GoodsOpt.GoodsCategoryId,
        },
        (result) => {
          this.AttributeGroup = result.Data;

          this.AttributeGroup.forEach((group) => {
            for (var i = 0; i < group.ProductAttribute.length; i++) {
              this.GoodsOpt.SkuAttribute.forEach((sku) => {
                if (group.ProductAttribute[i].Id == sku.AttributeId) {
                  group.OptProductAttribute = group.ProductAttribute[i].Id;
                }
              });
            }
          });
        }
      );
    },

    //打开编辑窗口
    OpenCreateGoodsWindow(item) {
      this.GoodsOpt = item;
      this.GoodsWindow = true;
    },
    //打开编辑窗口
    OpenGoodsWindow(item) {
      axios.apiMethod(
        "/stores/Goods/GetGoodsByIdDetailAsync?goodsId=" + item.Id,
        "get",
        {},
        (result) => {
          this.GoodsOpt = result.Data;
          this.GetAttributeGroupOrItemAll(this.GoodsOpt.Id);

          this.GoodsWindow = true;
        }
      );
    },
    GoodsDelete(item) {
      axios.apiMethod(
        "/stores/GoodsManage/DeleteGoods?id=" + item.Id,
        "get",
        {},
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetGoods();
            this.GoodsWindow = false;
          } else {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },
    //查询详细信息
    GetGoodsByIdDetail(item) {
      axios.apiMethod(
        "/stores/Goods/GetGoodsByIdDetailAsync?goodsId=" + item.Id,
        "get",
        {},
        (result) => {
          this.GoodsOpt = result.Data;
        }
      );
    },
    //插入商品
    InsertGoods() {
      this.GoodsOpt.SkuAttribute = [];
      this.AttributeGroup.forEach((s) => {
        if (s.OptProductAttribute != "")
          this.GoodsOpt.SkuAttribute.push({ Id: s.OptProductAttribute });
      });

      axios.apiMethod(
        "/stores/GoodsManage/InsertGoods",
        "post",
        this.GoodsOpt,
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetGoods();
            this.GoodsWindow = false;
          } else {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },

    //获取商品类型
    GetProductType() {
      const param = {};
      axios.apiMethod("/sys/SystemData/GetProductType", "get", {}, (result) => {
        if (result.Code == 200) {
          this.ProductTypes = result.Data;
        } else {
          ElMessageBox({
            message: result.FullMsg,
            type: "error",
          });
        }
      });
    },

    //获取所有分类
    GetCategoryAll() {
      const param = {};
      axios.apiMethod("/stores/Data/GetCategorys", "post", {}, (result) => {
        if (result.Code == 200) {
          this.Categorys = result.Data;
        } else {
          ElMessageBox({
            message: result.FullMsg,
            type: "error",
          });
        }
      });
    },

    // 获取商品
    GetGoods() {
      this.GoodsPadding.Where.Name = this.GoodsSearch;
      this.GoodsPadding.Where.SearchProductTypes = this.SearchProductTypes;

      const param = {};
      axios.apiMethod(
        "/stores/GoodsManage/GetGoods",
        "post",
        this.GoodsPadding,
        (result) => {
          if (result.Code == 200) {
            this.GoodsDatas = result.Data.Datas;
            this.GoodsPadding.Page = result.Data.Page;
            this.GoodsPadding.Size = result.Data.Size;
            this.GoodsPadding.Count = result.Data.Count;
            this.GoodsSearch = "";
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error",
            });
          }
        }
      );
    },
  },
};
</script>

<style scoped lang="less">
.inputSKU {
  width: 95%;
  height: 40px;
  border: 0px;
}

.tableSKU {
  border-collapse: collapse;
  width: calc(100% - 20px);
  tr {
    th {
      border: 1px solid #eee;
      height: 40px;
      text-align: left;
    }
    td {
      border: 1px solid #eee;
      height: 40px;
    }
  }
}

.avatar-uploader .avatar {
  width: 128px;
  height: 128px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.avatar-uploader-icon {
  border: 1px dashed #666;
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  text-align: center;
}

.box-card {
  width: calc(100% - 30px);
}
.overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.menu_item_icon {
  margin-right: 5px;
  font-size: 13pt;
}
</style>
